import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'styled-bootstrap-grid';
import ContentContainer from '../components/global/contentContainer';
import Helmet from 'react-helmet';

const Text = styled.div`
  font-size: ${props => props.fontSize || '20px'};
  text-align: center;

  & > p:first-of-type {
    margin-top: 0;
  }

  margin: ${props => props.singleTextboxMargin};

  &:nth-child(2) {
    margin: ${props => props.bottomMargin || '0 0 75px 0'};

    color: ${props => props.color};
  }

  & a {
    color: inherit;
  }

  @media (max-width: 767px) {
    margin: ${props => props.mobileBottomMargin};

    &:nth-child(2) {
      margin: 0 9px 40px 9px;
    }
  }

  @media (max-width: 576px) {
    font-size: 15px;
  }
`;

const ContainerTheme = {
  backgroundColor: '#ffffdc',
};

const FourOhFour = ({ data }) => {
  return (
    <>
      <Helmet>
        <title>404</title>
      </Helmet>

      <Row css={ContainerTheme} justifyContent="center" alignItems="center">
        <Col col={12}>
          <ContentContainer padding={'54px 36px'}>
            <Text>Sorry we couldn't find what you were looking for...</Text>
          </ContentContainer>
        </Col>
      </Row>
    </>
  );
};

export default FourOhFour;
